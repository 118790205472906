/**
 * 工厂模式 抽取常用的信息字段
 * 结合mobx
 *
 * amount 余额
 * udid 是否安装证书
 * avatar 头像
 * nickName 用户名
 * tryCount 试玩任务数
 * cheat 是否作弊
 * osVersion 手机系统版本
 * incomeTotal 总收入
 * role 角色类型 0：普通用户 1：申请中 2：vip
 * auditIncome 冻结金额
 * lucky 中奖者
 * channelUser 是否渠道用户
 */
class MenuData {
  constructor({
    amount,
    udid,
    avatar,
    nickName,
    tryCount,
    cheat,
    osVersion,
    incomeTotal,
    role,
    tag,
    auditIncome,
    lucky,
    channelUser,
    continueDays,
    isToday,
    isNewer,
    amountAndAudit,
    isTax,
    videoScoreToday,
    offlineWealth,
    inviterId,
    create_time,
    new_reward_show,
    game_show,
    test_user,
    red_packet_sum,
    run_red_pack_vsible,
    duoduo_sign,
    middle_audit_count,
    quick_aduit_warn,
    read_novel,
    upgrade,
    adType
  }) {
    this.amount = amount;
    this.udid = udid;
    this.avatar = avatar;
    this.nickName = nickName;
    this.tryCount = tryCount;
    this.cheat = cheat;
    this.incomeTotal = incomeTotal;
    this.role = role;
    this.tag = tag;
    this.auditIncome = auditIncome;
    this.lucky = lucky;
    this.channelUser = channelUser;
    this.continueDays = continueDays;
    this.isToday = isToday;
    this.isNewer = isNewer;
    this.amountAndAudit = amountAndAudit;
    this.isTax = isTax;
    this.videoScoreToday = videoScoreToday;
    this.offlineWealth = offlineWealth;
    this.inviterId = inviterId;
    this.create_time = create_time;
    this.new_reward_show = new_reward_show;
    this.game_show = game_show;
    this.test_user = test_user;
    this.red_packet_sum = red_packet_sum;
    this.run_red_pack_vsible = run_red_pack_vsible;
    this.duoduo_sign = duoduo_sign;
    this.middle_audit_count = middle_audit_count;
    this.quick_aduit_warn = quick_aduit_warn;
    this.read_novel = read_novel;
    this.upgrade = upgrade;
    this.adType = adType;
  }
}

export function menuData(data) {
  return new MenuData({
    amount: data.income_available,
    incomeTotal: data.income_total,
    udid: data.has_udid,
    avatar: data.avatar_url,
    nickName: data.nickname,
    tryCount: data.try_count,
    cheat: data.is_cheat,
    role: data.role,
    tag: data.is_show_banner,
    auditIncome: data.audit_income,
    lucky: data.lucky,
    channelUser: data.is_channel,
    continueDays: data.continue_days,
    isToday: data.is_today,
    isNewer: data.is_newer,
    amountAndAudit: data.income_available + data.audit_income,
    isTax: data.is_transfer_tax,
    videoScoreToday: data.video_score_today,
    offlineWealth: data.offline_wealth_value,
    inviterId: data.inviter_id,
    create_time: data.create_time,
    new_reward_show: data.new_reward_show,
    game_show: data.game_show,
    test_user: data.test_user,
    red_packet_sum: data.red_packet_sum,
    run_red_pack_vsible: data.run_red_pack_vsible,
    duoduo_sign: data.duoduo_sign,
    middle_audit_count: data.middle_audit_count,
    quick_aduit_warn: data.quick_aduit_warn,
    read_novel: data.read_novel,
    upgrade: data.upgrade,
    adType: data.ad_type,
  });
}

class UserData {
  constructor({
    userId,
    mobile,
    blackList,
    weChat,
    zhima,
    utmSource,
    sourceAppVersion,
    alipay_id,
    alipay_name,
    realName,
  }) {
    this.userId = userId;
    this.mobile = mobile;
    this.blackList = blackList;
    this.weChat = weChat;
    this.zhima = zhima;
    this.utmSource = utmSource;
    this.sourceAppVersion = sourceAppVersion;
    this.alipay_id = alipay_id;
    this.alipay_name = alipay_name;
    this.realName = realName;
  }
}

export function userData(data) {
  return new UserData({
    userId: data.id,
    mobile: data.mobile,
    blackList: data.is_in_blacklist,
    weChat: data.is_we_chat,
    zhima: data.zhima_time,
    utmSource: data.utm_source,
    sourceAppVersion: data.source_app_version,
    alipay_id: data.alipay_id,
    alipay_name: data.alipay_name,
    realName: data.real_name,
  });
}

class StaticsData {
  constructor({
    inviteCount,
    score,
    incomeToday,
    incomeInvite,
    inviteToday,
    inviteActive,
    rewardToday,
    scoreToday,
    figure,
    lastweekCount,
    wealthValue,
    inviteActiveCountToday,
  }) {
    this.inviteCount = inviteCount;
    this.score = score;
    this.incomeToday = incomeToday;
    this.incomeInvite = incomeInvite;
    this.inviteToday = inviteToday;
    this.inviteActive = inviteActive;
    this.rewardToday = rewardToday;
    this.scoreToday = scoreToday;
    this.figure = figure;
    this.lastweekCount = lastweekCount;
    this.wealthValue = wealthValue;
    this.inviteActiveCountToday = inviteActiveCountToday;
  }
}

export function staticsData(data) {
  return new StaticsData({
    inviteCount: data.invite_count,
    score: data.score_available,
    incomeToday: data.income_invite_today,
    incomeInvite: data.income_invite,
    inviteToday: data.invite_count_today,
    inviteActive: data.invite_active_count,
    inviteActiveCountToday: data.invite_active_count_today,
    //今日收益
    rewardToday: data.income_today,
    //今日金币收益
    scoreToday: data.score_today,
    //当月已提现金额
    figure: data.transfer_this_month,
    // 过去一周邀请数
    lastweekCount: data.invitee_count_last_week,
    wealthValue: data.wealth_value,
  });
}

class SignData {
  constructor({ checkIn, dayContinuity }) {
    this.checkIn = checkIn;
    this.dayContinuity = dayContinuity;
  }
}

export function signData(data) {
  return new SignData({
    checkIn: data.checkin,
    dayContinuity: data.day_continuity,
  });
}

class TryingGame {
  constructor({
    icon_url,
    short_name,
    issue,
    description,
    time_end,
    adid,
    source,
  }) {
    this.icon_url = icon_url;
    this.short_name = short_name;
    this.issue = issue;
    this.description = description;
    this.time_end = time_end;
    this.adid = adid;
    this.source = source;
  }
}

export function tryingGame(data) {
  return new TryingGame({
    icon_url: data.icon_url,
    short_name: data.short_name,
    issue: data.issue,
    description: data.description,
    time_end: data.time_end,
    adid: data.adid,
    source: data.source,
  });
}
